<!--  -->
<template>
  <div class="pageWrapper" style="height:100%">
    <vue-better-scroll
      class="wrapper"
      ref="scroll"
      :scrollbar="scrollbarObj"
      :startY="0"
    >
      <div
        class="singleSpectation"
        v-for="item in form.beforeInspection"
        :key="item.inspectionId"
      >
        <div
          class="titleBar"
          v-if="
            item.subScheduleInspections && item.subScheduleInspections.length
          "
        >
          {{ item.inspectionName }}
        </div>
        <div
          class="specificSpectation preparingSpectation"
          v-for="subitem in item.subScheduleInspections"
          :key="subitem.inspectionId"
        >
          <van-cell style="margin:10px 0">
            <van-checkbox
              v-model="subitem.inspectionResult"
              :disabled="!isEditing"
            >
              {{ subitem.inspectionName }}
              <template #icon>
                <van-checkbox />
              </template>
            </van-checkbox>
            <div class="remarkWrapper">
              <van-field
                :disabled="!isEditing"
                type="textarea"
                rows="1"
                autosize
                v-model="subitem.inspectionRemark"
                placeholder="若检查有异常，请在此处备注情况"
              />
            </div>
          </van-cell>
        </div>
      </div>
      <div class="titleBar">
        现场照片
      </div>
      <div class="uploadWrapper">
        <div class="picUpload">
          <van-uploader
            v-model="fileList"
            :multiple="false"
            :before-read="beforeRead"
            :after-read="afterRead"
            @delete="beforeDelete"
            v-if="isEditing"
          >
            <!-- <template #preview-cover="{ file }">
                <div class="preview-cover van-ellipsis">{{ file.name }}</div>
            </template> -->
          </van-uploader>
          <div class="picsReview" v-else>
            <van-image
              width="5rem"
              height="5rem"
              :src="item.imageUrl"
              v-for="item in images"
              :key="item.imageId"
              style="margin-right: 5px"
            />
          </div>
        </div>
      </div>

      <div class="specificSpectation">
        <van-cell style="margin:10px 0">
          <van-checkbox v-model="form.earlyArrive" :disabled="!isEditing">
            老师是否提前到场
            <template #icon>
              <van-checkbox />
            </template>
          </van-checkbox>
        </van-cell>
      </div>

      <div class="specificSpectation" style="margin-bottom:100px">
        <van-cell style="margin:10px 0">
          <van-checkbox v-model="form.notify" :disabled="!isEditing">
            是否告知主管领导
            <template #icon>
              <van-checkbox />
            </template>
          </van-checkbox>
        </van-cell>
      </div>
      <div class="emptyBox"></div>

      <div class="bottomButtonWrapper">
        <van-button
          v-if="isEditing"
          @click="handleSubmit"
          class="bottomButton"
          plain
          type="primary"
          size="small"
          style="color:#2bb7b3;font-size:.8rem;font-weight:500;border: 1px solid #2bb7b3;border-radius:5px"
          >提交</van-button
        >
      </div>
    </vue-better-scroll>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getRoomPreparingInfo,
  UploadPic,
  addBeforeSchedule,
  getMeetingProgressDetails
} from "@/api/meetingList";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      form: {
        imageIds: [],
        earlyArrive: false,
        notify: false
      },
      fileList: [],
      images: [],
      scrollbarObj: {
        fade: true
      }
    };
  },
  //监听属性 类似于data概念
  computed: {
    currentMeeting() {
      return this.$store.state.currentMeeting.meeting;
    },
    currentScheduleId() {
      return this.$store.state.currentMeeting.scheduleId;
    },
    isEditing() {
      return this.$store.state.currentMeeting.isEditing;
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getMeetingProgressDetails() {
      getMeetingProgressDetails({ scheduleId: this.currentScheduleId })
        .then(res => {
          if (res.code === 10000 && res.httpStatus === 200) {
            console.log(res.data);
            this.form.beforeInspection = res.data.scheduleInspectionList;
            this.form.notify = res.data.notify;
            this.form.earlyArrive = res.data.earlyArrive;
            this.images = res.data.images;
            console.log(this.form.beforeInspection);
          } else {
            this.$notify({ type: "warning", message: res.message });
          }
        })
        .catch(error => {
          console.log(error);
          this.$notify({ type: "warning", message: error.message });
        });
    },
    handleSubmit() {
      let param = {
        inspectionRequest: this.form.beforeInspection,
        imageIds: this.form.imageIds,
        earlyArrive: this.form.earlyArrive,
        notify: this.form.notify,
        workorderId: this.currentMeeting.workorderId
      };
      addBeforeSchedule(param)
        .then(res => {
          if (res.code === 10000 && res.httpStatus === 200) {
            this.$notify({ type: "success", message: "操作成功" });
            this.$store.commit("SetNavBarTitle", "会中工作");
            this.$router.push("/inprogressSchedule");
          } else {
            this.$notify({ type: "warning", message: res.message });
          }
        })
        .catch(error => {
          console.log(error);
          this.$notify({ type: "warning", message: error.message });
        });
    },
    beforeRead(file) {
      file.index = this.fileList.length;
      return true;
    },
    beforeDelete(file) {
      this.form.imageIds = this.form.imageIds.filter((item, index) => {
        return index !== file.file.index;
      });
      //   重新排index
      this.fileList = this.fileList.map((item, index) => {
        item.file.index = index;
        return item;
      });
    },
    afterRead(file) {
      UploadPic(file.file)
        .then(res => {
          if (res.code === 10000 && res.httpStatus === 200) {
            this.form.imageIds.push(res.data.imageId);
            this.$notify({ type: "success", message: "上传成功" });
          } else {
            this.$notify({ type: "warning", message: res.message });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getRoomPreparingInfo() {
      getRoomPreparingInfo({ roomId: this.currentMeeting.roomId }).then(res => {
        if (res.code === 10000) {
          console.log(res.data.beforeInspection, "res.data.beforeInspection");
          let processedData = res.data.beforeInspection.map(item => {
            if (!item.subInspections) {
              return item;
            }

            item.subInspections = item.subInspections.map(subItem => {
              subItem.inspectionRemark = "";
              subItem.inspectionResult = false;
              return subItem;
            });
            item.subScheduleInspections = item.subInspections;
            item.inspectionResult = true;
            return item;
          });
          console.log(processedData);
          this.$set(this.form, "beforeInspection", processedData);
          this.$nextTick(() => {
            if (!this.isEditing) {
              this.getMeetingProgressDetails();
            }
          });
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getRoomPreparingInfo();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SetNavBarTitle", to.meta.name);
    next();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
/* @import url(); 引入公共css类*/
.singleSpectation {
  margin-bottom: 10px;
}
.uploadWrapper {
  background-color: #fff;
  margin: 10px 0;
  border-radius: 3px;
  padding: 0.5rem;
}

.uploadWrapper .van-uploader__upload {
  margin: 0;
}
.remarkWrapper .van-cell.van-field {
  padding: 10px 0;
}
.remarkWrapper .van-cell.van-field .van-cell__value {
  border: 1px solid #9d9fa1;
}

.remarkWrapper .van-cell.van-field .van-field__control {
  padding-left: 3px;
}

.singleSpectation .van-checkbox__icon {
  position: relative;
}

.singleSpectation .van-checkbox__icon::before {
  display: inline-block;
  content: "正常请打勾";
  font-size: 0.2rem;
  color: #9d9fa1;
  position: absolute;
  right: -0.3rem;
  width: 5rem;
}
</style>
